<template>
  <div style="width: 100%">
    <div v-if="isStockManager">
      <v-btn
        class="primary"
        block
        @click="showAddPartsDialog = true"
      >
        {{ $t('c.addStock') }}
      </v-btn>
      <add-part-or-update-stock
        v-if="showAddPartsDialog"
        :part="part"
        :entity-parts="part.entity_parts"
        :entity-id="entityId"
        @cancel="showAddPartsDialog = false"
        @finish="showAddPartsDialog = false"
      />
    </div>
    <div
      v-else-if="isEntityAdmin"
    >
      <handle-item-amount
        v-if="itemOnCart"
        :part="itemOnCart"
      />
      <v-btn
        v-else
        block
        @click="addPartToCart"
      >
        Add to cart
      </v-btn>
    </div>
  </div>
</template>

<script>
import { rolesIds } from '@/roles'
import HandleItemAmount from '@/views/dashboard/components/Parts/HandleItemAmount'
import AddPartOrUpdateStock from '@/views/dashboard/pages/ShowPart/components/AddPartOrUpdateStock'
export default {
  name: 'PartActions',
  // eslint-disable-next-line vue/no-unused-components
  components: { AddPartOrUpdateStock, HandleItemAmount },
  props: {
    part: {
      type: Object,
      required: true,
    },
    entityId: {
      type: Number,
      default: () => null,
    },
  },
  data () {
    return {
      showAddPartsDialog: false,
    }
  },
  computed: {
    userRole () {
      return this.$store.getters.getUserRole
    },
    isStockManager () {
      return this.userRole.id === rolesIds.stockManager
    },
    isEntityAdmin () {
      return this.userRole.id === rolesIds.entityAdmin
    },
    itemOnCart () {
      return this.$store.getters.getItemOnCart(this.part)
    },
  },
  methods: {
    addPartToCart () {
      this.$store.dispatch('addItemToCart', this.part)
    },
    handleCreatedEntityPart (data) {
      this.part.entity_parts.push(data)
      this.$store.commit('snackBar/setSnack', {
        color: 'primary',
        message: 'Added part to entity',
      })
      this.showAddPartsDialog = false
    },
    handleEntityPartUpdated () {
      this.getPart()
      this.$store.commit('snackBar/setSnack', {
        color: 'primary',
        message: 'Stock updated',
      })
      this.showAddPartsDialog = false
    },
    handleCancel () {
      this.showAddPartsDialog = false
    },
  },
  i18n: {
    messages: {
      en: {
        c: {
          addStock: 'Add Stock',
        },
      },
      it: {
        c: {
          addStock: 'Aggiungi Stock',
        },
      },
    },
  },
}
</script>

<style scoped>

</style>

<template>
  <v-text-field
    v-model.number="part.amount"
    type="number"
    min="0"
    :outlined="outlined"
    @change="updateAmount"
  />
</template>

<script>
export default {
  name: 'HandleItemAmount',
  props: {
    part: {
      type: Object,
      required: true,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    updateAmount (value) {
      if (value === '') {
        value = 0
      }
      this.$store.dispatch('setItemAmount',
                           { item: this.part, value })
    },
  },
}
</script>

<style scoped>

</style>

<template>
  <parts-list
    :headers="headers"
    :parts="parts"
    :loading="loading"
    :filters="filters"
    :entity-id="entityId"
    :no-parts-found-msg="$t('c.noPartsFound') "
    :handle-collapse="handleCollapse"
  >
    <template slot="search">
      <base-material-card
        :header="false"
      >
        <v-text-field
          v-model="search"
          :label="$t('search')"
          @input="debouncedSearch"
        />
      </base-material-card>
    </template>
  </parts-list>
</template>

<script>
import PartsList from '@/views/dashboard/lists/PartsList'
import PartsRepository from '@/api/PartsRepository'
import PartsListMixin from '@/views/dashboard/pages/PartsIndex/components/PartsListMixin'
import { capitalize } from '@/helpers/helpers'
export default {
  name: 'EntityPartsListWrapper',
  components: { PartsList },
  mixins: [PartsListMixin],
  props: {
    entityId: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: Number | String,
      required: true,
    },
  },
  data () {
    return {
      headers: [
        { value: 'avatar' },
        { text: capitalize(this.$t('name')), value: 'part.name' },
        { text: capitalize(this.$t('stock')), value: 'stock' },
      ],
      filters: [
        /* {
          title: 'Stock Availability',
          queryParam: 'stock',
          collapsed: false,
          options: [
            {
              checked: false,
              value: 'stock_in',
              description: 'In Stock',
            },
            {
              checked: false,
              value: 'stock_missing',
              description: 'Out of Stock',
            },
          ],

        }, */
        {
          title: capitalize(this.$t('category')),
          queryParam: 'category',
          collapsed: false,
          options: [],
        },
      ],
    }
  },
  methods: {
    apiCall (params) {
      params.entityParts = this.entityId
      return PartsRepository.index(params)
    },
  },
  i18n: {
    messages: {
      en: {
        c: {
          noPartsFound: 'No parts found.',
        },
      },
      it: {
        c: {
          noPartsFound: 'Nessuna parte trovata.',
        },
      },
    },
  },
}
</script>

<style scoped>

</style>

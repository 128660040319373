<template>
  <div
    v-if="isStockManager || isOperator"
    class="pa-2"
  >
    <v-divider />
    <div class="d-flex justify-space-between align-center">
      <h4 class="ml-2">
        {{ $t('quantities') | capitalize }}
      </h4>
      <v-btn
        class="pa-0 white"
        icon
        color="pink"
        @click="showBody = ! showBody"
      >
        <v-icon>mdi-chevron-down</v-icon>
      </v-btn>
    </div>
    <div v-if="showBody">
      <div v-if="part.entity_parts.length">
        <div
          v-for="{entity,stock_in,stock_missing,id} in part.entity_parts"
          :key="id"
          class="d-flex justify-space-between"
        >
          <div>
            {{ entity.name }}
          </div>
          <div>
            <span
              v-if="stock_missing"
              class="red--text"
            > {{ stock_missing }}</span>
            <span v-else>  {{ stock_in }}</span>
          </div>
        </div>
      </div>
      <div v-else>
        <p>Any entity has this part</p>
      </div>
    </div>
  </div>
</template>

<script>
import { rolesIds } from '@/roles'

export default {
  name: 'PartBody',
  props: {
    part: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      showBody: false,
    }
  },
  computed: {
    user () {
      return this.$store.getters.getUserLogged
    },
    isStockManager () {
      return this.user.user_role_id === rolesIds.stockManager
    },
    isOperator () {
      return this.user.user_role_id === rolesIds.operator
    },
  },
}
</script>

<style scoped>

</style>

<template>
  <div>
    <base-material-card
      :header="false"
      class="px-5 py-3"
    >
      <v-row justify="center">
        <v-col
          cols="12"
          md="3"
        >
          <slot name="createPartButton" />
          <slot name="search" />
          <base-material-card
            :header="false"
          >
            <check-box-filter
              v-for="filter in filters"
              :key="filter.title"
              :filters="filter"
              @collapse="handleCollapse(filter)"
            />
          </base-material-card>
        </v-col>
        <v-col
          cols="12"
          md="9"
        >
          <v-row v-if="parts.length">
            <v-col
              v-for="part in parts"
              :key="part.id"
              cols="12"
              md="6"
              lg="3"
              xl="3"
            >
              <part
                :part="part"
                :entity-id="entityId"
              />
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col
              class="mt-8"
            >
              <h3> {{ noPartsFoundMsg }}</h3>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </base-material-card>
  </div>
</template>

<script>

import Part from '@/views/dashboard/components/Parts/PartBody/Part'
import CheckBoxFilter from '@/views/dashboard/components/CheckBoxFilter'

export default {
  name: 'PartsList',
  components: { CheckBoxFilter, Part },
  props: {
    headers: {
      type: Array,
      required: true,
    },
    parts: {
      type: Array,
      required: true,
    },
    filters: {
      type: Array,
      default: () => [],
    },
    entityId: {
      type: Number,
      default: () => null,
    },
    noPartsFoundMsg: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      isCollapse: false,
      loading: true,
    }
  },
  methods: {
    handleCollapse (filter) {
      this.isCollapse = true
      filter.collapsed = !filter.collapsed
    },
  },

}
</script>

<style scoped>

</style>

<template>
  <v-card>
    <router-link
      :to="{ path: `/parts/${part.id}`}"
    >
      <v-img
        contain
        :src="part.thumb"
        gradient="to bottom, rgba(0,0,0,.1), rgba(222,222,222,.5)"
        height="200px"
      />
    </router-link>
    <v-card-text>
      <div class="product-name">
        <router-link
          class="blue--text"
          :to="{ path: `/parts/${part.id}`}"
          v-text="part.name"
        />
      </div>
      <p
        class="grey--text"
        v-text="part.category.name"
      />
    </v-card-text>

    <v-row class="justify-end mb-4">
      <v-col class="text-right mr-2">
        <h4>{{ $t('availability') }}</h4>
        <div
          class="inline-flex items-center gap-1 text-xs"
        >
          {{ stockIn - stockMissing }}
          <span
            v-if="stockMissing > 0"
            class="red--text"
          >
            {{ $t('stockMissing') }}
          </span>
          <span
            v-else-if="stockIn === 1"
            class="yellow--text text--darken-3"
          >
            {{ $t('lowStock') }}
          </span>
          <span
            v-else-if="stockIn === 0"
            class="yellow--text text--darken-3"
          >
            {{ $t('noneStock') | capitalize }}
          </span>
          <span
            v-else
            class="green--text"
          >
            {{ $t('available') }}
          </span>
        </div>
      </v-col>
    </v-row>
    <part-body
      :part="part"
    />
    <v-card-actions
      class="grey lighten-4"
      style="height: 60px"
    >
      <part-actions
        :part="part"
        :entity-id="entityId"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
import PartActions from '@/views/dashboard/components/Parts/PartActions'
import PartBody from '@/views/dashboard/components/Parts/PartBody/PartBody'

export default {
  name: 'Part',
  components: { PartBody, PartActions },
  props: {
    part: {
      type: Object,
      required: true,
    },
    entityId: {
      type: Number,
      default: () => null,
    },
  },
  computed: {
    stockIn () {
      return this.part.entity_parts.reduce((acc, element) => acc + element.stock_in, 0)
    },
    stockMissing () {
      return this.part.entity_parts.reduce((acc, element) => acc + element.stock_missing, 0)
    },

    user () {
      return this.$store.getters.getUserLogged
    },
  },
  methods: {
    getPath () {
      return this.part.image
    },
  },
  i18n: {
    messages: {
      en: {
        availability: 'Availability',
        available: 'Available',
        lowStock: 'LowStock',
        stockMissing: 'Stock Missing',
      },
      it: {
        availability: 'Disponibilità',
        available: 'Disponibile',
        lowStock: 'Scorte Basse',
        stockMissing: 'Stock Mancante',
      },
    },
  },
}
</script>

<style scoped>
.product-image {
  border-radius: 6px 6px 0 0;
}
.product-name {
  margin: 5px 0;
  max-height: 40px;
  min-height: 40px;
  overflow: hidden;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
</style>

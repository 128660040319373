<script>
import CategoriesRepository from '@/api/CategoriesRepository'
import EventBus from '@/plugins/EventBus'
import _ from 'lodash'
export default {
  name: 'PartsListMixin',
  data () {
    return {
      parts: [],
      search: '',
      loading: true,
      isCollapse: false,
    }
  },
  watch: {
    filters: {
      deep: true,
      handler () {
        if (this.isCollapse) {
          this.isCollapse = false
        } else {
          this.fetchParts()
        }
      },
    },
  },
  async created () {
    await this.fetchParts()
    await this.fetchCategories()
    // dont need to fetch on create, because the watcher runs after tech the categories
  },
  mounted () {
    const instance = this
    EventBus.$on('addedStockToEntityPart', (partUpdated) => {
      // eslint-disable-next-line no-unused-vars
      const parts = instance.parts
      const part = parts.find(e => e.id === partUpdated.part_id)
      const partEntity = part.entity_parts.find(e => e.entity_id === partUpdated.entity_id)
      partEntity.stock_in = partUpdated.stock_in
      partEntity.stock_missing = partUpdated.stock_missing
    })

    EventBus.$on('createdEntityPart', ({
      partId,
      entityPart,
    }) => {
      // eslint-disable-next-line no-unused-vars
      const parts = instance.parts
      const part = parts.find(e => e.id === partId)
      part.entity_parts.push(entityPart)
    })
  },
  methods: {
    fetchCategories () {
      return new Promise((resolve, reject) => {
        CategoriesRepository.index()
          .then(({ data }) => {
            this.filters[0].options = data
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchParts () {
      const queryParams = {
        // per_page: 20,
        // page,
        search: this.search,
        with: 'entityParts,media',
      }

      for (const filter of this.filters) {
        let checkedOptions = filter.options.filter(option => {
          return option.checked === true
        })

        checkedOptions = checkedOptions.map(option => {
          return option.value
        })

        if (checkedOptions.length > 0) {
          queryParams[filter.queryParam] = checkedOptions.join()
        }
      }
      this.apiCall(queryParams)
        .then(({ data }) => {
          this.parts = data.data
          this.loading = false
        })
        .catch(() => {
        })
    },
    debouncedSearch: _.debounce(function () {
      this.fetchParts()
    }, 500),
    handleCollapse (filter) {
      this.isCollapse = true
      filter.collapsed = !filter.collapsed
    },
  },
}
</script>
